import { Colors, Lightning, Registry } from "@lightningjs/sdk";
import theme from "../../lib/theme";
import { MovieDetails } from "../MovieDetails/MovieDetails";
import { BrandingModel } from "../../lib/models";
import { redirectToSelectedMovie } from "../../lib/utils";

interface BrandingTemplateSpec extends Lightning.Component.TemplateSpec {
    data: BrandingModel[];
    Branding: typeof MovieDetails;
    BrandingNavigation: object;
}

export class Branding
    extends Lightning.Component<BrandingTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<BrandingTemplateSpec>
{
    _index = 0;
    _branding: BrandingModel[] = [];
    _timeout: any;

    readonly BrandingNavigation = this.getByRef("BrandingNavigation")!;

    static override _template(): Lightning.Component.Template<BrandingTemplateSpec> {
        return {
            BrandingNavigation: {
                x: theme.menu.w,
                w: theme.banner.w - theme.menu.w,
                y: theme.banner.h - 28,
                flex: {
                    direction: "row",
                    justifyContent: "center"
                },
                zIndex: 50
            }
        };
    }

    override _getFocused(): Lightning.Component | null | undefined {
        return this;
    }

    override _focus() {
        this._setNextNavigationTimeout();

        if (this._branding[this._index]) {
            // @ts-ignore
            this.application.emit("updateSelectedMovie", this._branding[this._index]!);
        }
    }

    override _unfocus() {
        this._clearTimeout();
    }

    override _handleLeft() {
        if (this._index === 0) {
            return false;
        } else {
            this._navigateBranding(-1);
        }
    }

    override _handleRight() {
        this._navigateBranding();
    }

    override _handleEnter() {
        const selectedItem = this._branding[this._index]!;
        if (selectedItem && "movieId" in selectedItem && selectedItem.movieId) {
            redirectToSelectedMovie({
                id: selectedItem.movieId,
                type:
                    selectedItem.type === "episode"
                        ? "Episode"
                        : selectedItem.type === "movie"
                          ? "LiveStream"
                          : "TvSeries",
                isFree: selectedItem.isFree
            });
        }
    }

    _clearTimeout() {
        if (this._timeout) {
            Registry.clearTimeout(this._timeout);
            this._timeout = undefined;
        }
    }

    _setNextNavigationTimeout() {
        this._clearTimeout();
        this._timeout = Registry.setTimeout(() => {
            this._navigateBranding();
        }, 5000);
    }

    _navigateBranding(direction = 1) {
        this._clearTimeout();

        if (!this._branding.length) return;

        this.BrandingNavigation.children[this._index]?.patch({
            texture: Lightning.Tools.getRoundRect(
                16,
                16,
                8,
                0,
                undefined,
                true,
                Colors(theme.color.text).alpha(0.5).get()
            )
        });

        this._index =
            direction === 1
                ? this._index + 1 > this._branding.length - 1
                    ? 0
                    : this._index + 1
                : this._index === 0
                  ? this._branding.length - 1
                  : this._index - 1;

        // @ts-ignore
        this.application.emit("updateSelectedMovie", this._branding[this._index]!);

        this.BrandingNavigation.children[this._index]?.patch({
            texture: Lightning.Tools.getRoundRect(
                16,
                16,
                8,
                0,
                undefined,
                true,
                Colors(theme.color.focus).alpha(1).get()
            )
        });

        if (this.hasFocus()) {
            this._setNextNavigationTimeout();
        }
    }

    set data(value: BrandingModel[]) {
        this._branding = value;
        if (this._branding.length) {
            this.BrandingNavigation.children = this._branding.map((item: any, index: number) => ({
                w: 16,
                h: 16,
                flexItem: {
                    marginLeft: 4,
                    marginRight: 4
                },
                texture: Lightning.Tools.getRoundRect(
                    16,
                    16,
                    8,
                    0,
                    undefined,
                    true,
                    Colors(index === 0 ? theme.color.focus : theme.color.text)
                        .alpha(index === 0 ? 1 : 0.5)
                        .get()
                )
            }));
        } else {
            this._setState("ListState");
        }
    }
}
