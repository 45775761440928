import {
    contentListItemSelectFragment,
    contentGroupItemSelectFragment,
    contentSearchFragment,
    userFragment
} from "./fragments";
import { QGLResponse } from "./types";
import { api } from "./index";
import { ApiParams, CategoryModel, MovieModel } from "../lib/models";
import configs from "../lib/configs";

export const getWatchListQuery = `
	query GetWatchList ($limit: Int, $offset: Int) {
	  watchList {
	  	isWatchList
	  	items (limit: $limit, offset: $offset) {
	  	    ...ContentGroupItemSelectFragment
	  	    __typename
	  	}
		__typename
	  }
	}
	${contentGroupItemSelectFragment}
`;

export const getBrandingQuery = `
    query GetBranding {
        branding {
            title
            description
            type
            backdropUrl
            backdropWideUrl
            movieId
        }
    }
`;

export const getDashboardQuery = `
    query GetDashboard ($limit: Int, $offset: Int, $includeCollections: Boolean) {
      dashboard {
        id
        items (limit: $limit, offset: $offset, includeCollections: $includeCollections) {
          ... on ContinueWatching {
            isContinueWatching
            name
            items {
              ...ContentItemsSelect
            }
            __typename
          }
          ... on Trending {
            isTrending
            name
            items {
              ...ContentItemsSelect
            }
            __typename
          }
          ... on Category {
            id
            name
            items {
              ...ContentItemsSelect
            }
            __typename
          }
          __typename
        }
        __typename
      }
    }
    ${contentListItemSelectFragment}
`;

export const getContentItemQuery = `
    query GetContentItem($id: String!) {
      contentItem(id: $id) {
      ... on Collection {
          id
          title
          description
          coverUrl
          backdropUrl
          releaseYear
          inWatchList
          items {
            ...ContentItemsSelect
          }
          __typename
        }
        ... on TvSeries {
          id
          title
          description
          coverUrl
          backdropUrl                                
          inWatchList
          releaseYear
          seasons {
            id
            seasonNumber
            releaseYear
            episodes {
                id
                title
                description
                duration
                coverUrl
                isFree
                __typename
              }
          }
          trailer {
            url
          }
          __typename
        }
        ... on Movie {
          id
          title
          description
          coverUrl
          backdropUrl
          inWatchList
          duration
          releaseYear
          isFree
          progress {
            current
          }
          trailer {
            url
          }
          __typename
        }
      }
    }
    ${contentListItemSelectFragment}
    
`;

export const getStreamQuery = `
    query GetStream($id: String!) {
      stream(id: $id) {
        url
        format
        content {
          ... on Episode {
            progress {
              current
            }
          }
          ... on Movie {
            progress {
              current
            }
          }
        }
      }
    }
`;

export const getContentItemById = async (id: string): Promise<MovieModel> => {
    const { data }: QGLResponse = await api("graphql", {
        method: "POST",
        body: { query: getContentItemQuery, variables: { id: id } }
    });

    return data.contentItem;
};

export const getBranding = async (): Promise<CategoryModel[]> => {
    const {
        data: { branding }
    }: QGLResponse = await api("graphql", {
        method: "POST",
        body: { query: getBrandingQuery }
    });

    return branding.filter((v: any) => v.type !== "external");
};

export const getDashboard = async (offset = 0): Promise<CategoryModel[]> => {
    const {
        data: { dashboard }
    }: QGLResponse = await api("graphql", {
        method: "POST",
        body: { query: getDashboardQuery, variables: { limit: 15, offset: offset, includeCollections: true } }
    });

    return dashboard.items;
};

export const getCategories = async (): Promise<CategoryModel[]> => {
    const {
        data: { categories }
    }: QGLResponse = await api("graphql", {
        method: "POST",
        body: {
            query: `
            query GetCategories ($limit: Int, $offset: Int, $includeCollections: Boolean) {
                categories (limit: $limit, offset: $offset, includeCollections: $includeCollections) {
                id
                name
                items{
                   ...ContentItemsSelect
                }
                __typename
              }
              __typename
            }
            ${contentListItemSelectFragment}
        `,
            variables: { limit: 10, offset: 0, includeCollections: true }
        }
    });

    return categories.filter((cat: CategoryModel) => !!cat.items.length);
};

export const getWatchList = async () => {
    const {
        data: { watchList }
    }: QGLResponse = await api("graphql", {
        method: "POST",
        body: { query: getWatchListQuery, variables: { limit: 10, offset: 0 } }
    });

    return watchList.items;
};

export const getStream = async (id: string) => {
    const { data, errors }: QGLResponse = await api("graphql", {
        method: "POST",
        body: { query: getStreamQuery, variables: { id: id } }
    });

    if (errors && errors[0]?.message === "Unauthorized") {
        throw new Error("401");
    }

    return data.stream;
};

export const getContentByCategory = async (categoryId: number, offset: number) => {
    const {
        data: { category }
    }: QGLResponse = await api("graphql", {
        method: "POST",
        body: {
            query: `
                query GetCategory($id: String!, $limit: Int!, $offset: Int!, $includeCollections: Boolean) {
                  category(id: $id) {
                      items (limit: $limit, offset: $offset, includeCollections: $includeCollections) {
                          ...ContentItemsSelect
                      }
                  }
                },
                ${contentListItemSelectFragment}
            `,
            variables: { id: categoryId, offset: offset, limit: 10, includeCollections: true }
        }
    });

    return category.items;
};

export const getContinueWatching = async () => {
    const {
        data: { continueWatching }
    }: QGLResponse = await api("graphql", {
        method: "POST",
        body: {
            query: `
                query GetContinueWatching {
                  continueWatching {
                      items {
                        ...ContentItemsSelect
                      }
                  }
                },
            ${contentListItemSelectFragment}
                
            `
        }
    });

    return continueWatching.items;
};

export const getTrending = async () => {
    const {
        data: { trendingList }
    }: QGLResponse = await api("graphql", {
        method: "POST",
        body: {
            query: `
                query GetTrending($includeCollections: Boolean) {
                  trendingList(includeCollections: $includeCollections) {
                      items {
                        ...ContentItemsSelect
                      }
                  }
                },
            ${contentListItemSelectFragment}
            `,
            variables: { includeCollections: true }
        }
    });

    return trendingList.items;
};

export const search = async (params: ApiParams, signal: any) => {
    const {
        data: { search }
    }: QGLResponse = await api("graphql", {
        signal,
        method: "POST",
        body: {
            query: `
                  query Search ($search: String, $limit: Int, $includeCollections: Boolean, $includeEpisodes: Boolean){
                      search {
                        id
                        items (search: $search, limit: $limit, includeCollections: $includeCollections, includeEpisodes: $includeEpisodes) {
                          ...on ContentGroup {
                            id
                            items {
                              ...on TvSeries {
                                id
                                title
                                coverUrl
                              __typename
                              }
                              ...on Episode {
                                id
                                title
                                coverUrl
                              __typename
                              }
                               ...on Movie {
                                id
                                title
                                coverUrl
                              __typename
                              }
                              ...on Collection {
                                id
                                title
                                coverUrl
                              __typename
                              }
                            }
                          }
                        }
                      }
                  }
            `,
            variables: {
                search: params.query,
                limit: 30,
                includeCollections: true,
                includeEpisodes: configs.search?.includeEpisodes || false
            }
        }
    });

    return search.items;
};

export const getUserData = async () => {
    const { data }: QGLResponse = await api("graphql", {
        method: "POST",
        headers: {
            "x-app-init": "true"
        },
        body: {
            query: `
                query GetUser {
                  user {
                    ...UserData
                  }
                },
                ${userFragment}
            `
        }
    });

    return data.user;
};

export const getUserStatus = async () => {
    const { data }: QGLResponse = await api("graphql", {
        method: "POST",
        body: {
            query: `
                query GetUserStatus {
                  status {
                    ...UserData
                  }
                },
                ${userFragment}
            `
        }
    });

    return data.status;
};

export const getSubscriptionData = async () => {
    const { data }: QGLResponse = await api("graphql", {
        method: "POST",
        body: {
            query: `
                query GetSubscriptionItem {
                  subscriptionsList{
                    price
                  }
                }
            `
        }
    });

    return data.subscriptionsList;
};
