import { Profile, Router, Storage } from "@lightningjs/sdk";

import { MediaType, UserModel } from "./models";
import { v4 as uuidv4 } from "uuid";
import configs from "./configs";
import { getUserData, getUserStatus } from "../api/queries";
// import { api } from "../api";

export const SPLASH_TIME = 2600;

export const SUBSCRIPTION_LINK = "pay.alteox.com";

export interface PAGE {
    name: string;
    link: string;
}
export const PAGES = {
    home: {
        name: "home",
        link: "home"
    },
    live: {
        name: "live",
        link: "live"
    },
    browse: {
        name: "browse",
        link: "browse"
    },
    search: {
        name: "search",
        link: "search"
    },
    favorite: {
        name: "favorite",
        link: "favorite"
    },
    info: {
        name: "info",
        link: "info"
    },
    settings: {
        name: "settings",
        link: "settings"
    },
    exit: {
        name: "exit",
        link: "exit"
    }
};

export const PLATFORMS = {
    lg: "lg",
    samsung: "samsung",
    posttv: "posttv",
    metrological: "metrological",
    nettv: "nettv",
    whaletv: "whaletv",
    philips: "philips",
    hisense: "hisense",
    androidtv: "androidtv",
    firetv: "firetv",
    comcast: "comcast"
};

export const STORAGE_KEYS = {
    user: "user",
    platform: "platform",
    uid: "uid",
    token: "token",
    selectedLanguage: "selectedLanguage",
    recentSearch: "recentSearch",
    lastMovieSelectedIndex: "lastMovieSelectedIndex",
    lastCategorySelectedIndex: "lastCategorySelectedIndex",
    audioLang: "audioLang",
    subLang: "subLang"
};

export const getPlatform = async () => {
    return Storage.get(STORAGE_KEYS.platform) || (await Profile.platform());
};

export const deviceId = async () => {
    let uid = Storage.get(STORAGE_KEYS.uid);

    if (uid) return uid;

    uid = await Profile.uid();

    // local browser
    if (uid === "ee6723b8-7ab3-462c-8d93-dbf61227998e") {
        uid = uuidv4();
    }

    Storage.set(STORAGE_KEYS.uid, uid);

    return uid;
};

export const getUserId = () => {
    return Storage.get(STORAGE_KEYS.user)?.id || "";
};

export const formatDuration = (progress: number, duration: number, useSeconds?: boolean) => {
    if (!progress || !duration) return "";

    progress = new Date(0, 0, 0, 0, 0, 0, 0).setSeconds(progress);
    duration = new Date(0, 0, 0, 0, 0, 0, 0).setSeconds(duration);

    return `${new Date(progress).toLocaleString("en-GB", {
        hour: "numeric",
        minute: "numeric",
        second: "numeric"
    })} - ${new Date(duration).toLocaleString("en-GB", {
        hour: "numeric",
        minute: "numeric",
        second: "numeric"
    })}`;
};

interface FormatTimeProps {
    useSeconds: boolean;
    hideEmpty: boolean;
}

export const formatTime = (
    value: number | undefined,
    { useSeconds, hideEmpty }: FormatTimeProps = {
        useSeconds: true,
        hideEmpty: true
    }
) => {
    if (!value && hideEmpty) return "";

    const totalMinutes = value ? Math.floor(value / 60) : 0;

    const seconds = value ? Math.floor(value % 60) : 0;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = Math.floor(totalMinutes % 60);

    return hideEmpty && hours === 0 && minutes === 0 && seconds === 0
        ? ""
        : hours > 0
          ? `${hours}h ${minutes > 0 ? `${minutes}m` : ""}`
          : `${minutes}m ${useSeconds ? `${seconds}s` : ""}`;
};

export const storageSaveUser = (data: UserModel | undefined) => {
    if (data) {
        Storage.set(STORAGE_KEYS.user, data);
    } else {
        Storage.remove(STORAGE_KEYS.user);
    }
};

export const getInfoFromTitle = (title: string) => {
    title = title || "";

    const separator = title.lastIndexOf("-") === -1 ? ":" : "-";

    return {
        title: title.substring(0, title.lastIndexOf(separator)) || title,
        category: title.split(separator).pop() || ""
    };
};

export const isExternalPlatform = () => {
    return (
        Storage.get(STORAGE_KEYS.platform) === PLATFORMS.androidtv ||
        Storage.get(STORAGE_KEYS.platform) === PLATFORMS.firetv
    );
};

export const isIAPSupported = () => {
    return (isExternalPlatform() && configs.iap) || Storage.get(STORAGE_KEYS.platform) === PLATFORMS.firetv;
};

export const isPostTvPlatform = () => {
    return Storage.get(STORAGE_KEYS.platform) === PLATFORMS.posttv;
};

export const isSubscriptionActive = () => {
    const user = Storage.get(STORAGE_KEYS.user);
    return user?.subscribed;
    // return user && user.currentPeriodEnd && user.currentPeriodEnd > Date.now() / 1000;
};

export const navigateToPlayback = (id: any) => {
    Router.navigate(`playback/${id}`, {
        keepAlive: Router.getActiveHash() === "search"
    });
};

export const getMediaRedirect = async (
    id: any,
    {
        isFree
    }: {
        isFree: boolean;
    }
) => {
    // console.log("isSubscriptionActive()", isSubscriptionActive());
    // console.log("isExternalPlatform()", isExternalPlatform());
    // console.log("isIAPSupported()", isIAPSupported());

    if (isFree || isSubscriptionActive()) {
        navigateToPlayback(id);
    } else {
        if (isIAPSupported()) {
            const user = Storage.get(STORAGE_KEYS.user);
            if (!user?.isLoggedIn) {
                Router.focusWidget("IAPSubscription");
            } else {
                // try to sync first
                try {
                    console.log("TRY SYNC");
                    await window.setSubscriptionData(window.Android!.getSubscriptionData(), true);
                } catch (e) {
                    console.log("SYC ERR");
                    console.log(e);
                }

                console.log("after sync: isActive  " + isSubscriptionActive());
                if (!isSubscriptionActive()) {
                    console.log("call createSubscription");
                    Router.focusWidget("IAPSubscription");
                } else {
                    navigateToPlayback(id);
                }
            }
        } else if (isPostTvPlatform()) {
            Router.focusWidget("PostTvSubscription");
        } else {
            Router.focusWidget("Auth");
        }
    }
};

export const redirectToSelectedMovie = ({
    id,
    type,
    isFree
}: {
    id: number | string;
    type: MediaType;
    isFree: boolean;
}) => {
    if (type === "Episode" || type === "LiveStream") {
        getMediaRedirect(id, {
            isFree: isFree
        });
    } else if (type === "Movie") {
        Router.navigate(`movie/${id}`, {
            keepAlive: true
        });
    } else {
        Router.navigate(`series/${id}`, {
            keepAlive: true
        });
    }
};

export const refreshUserData = async (isOnStart?: boolean) => {
    try {
        // save user obj
        const { token, ...userData } = isOnStart ? await getUserData() : await getUserStatus();
        storageSaveUser(userData);

        // non posttv
        // refresh auth token
        if (!isPostTvPlatform() && token) {
            Storage.set(STORAGE_KEYS.token, token);
        }

        if (isIAPSupported()) {
            // call getPurchaseToken for google, user id is not available there.
            // might be as well empty
            // todo: show 'pls login screen'
            const userId =
                Storage.get(STORAGE_KEYS.platform) === PLATFORMS.androidtv
                    ? window.Android!.getPurchaseToken()
                    : window.Android!.getUserId()!;

            storageSaveUser({
                userId,
                // currentPeriodEnd: null,
                subscribed: false
            });
            try {
                console.log("TRY SYNC");
                await window.setSubscriptionData(window.Android!.getSubscriptionData(), true);
            } catch (e) {
                console.log("SYC ERR");
                console.log(e);
            }
        }
    } catch (e) {
        storageSaveUser(undefined);
    }
};
