const configs = {
  appId: "prime-fight",
  appName: "Primefight",
  posthog: "phc_sFMaEs3dbNF3Vl5T8W9eJZLdgXp1RxtVvJk0IcZsn85",
  modules: {
    home: true,
    live: false,
    search: true,
    series: false,
    movies: false,
    watchlist: false,
    recording: false,
    favorite: false,
    branding: false,
  },
  playback: {
    subtitleLanguages: false,
    audioLanguages: false,
  },
  banner: {
    useCoverUrl: false,
    hasStaticBanner: false,
  },
  settings: {
    terms: "https://alteox.com/en/terms-conditions/prime-fight",
    email: "",
  },
  iap: {
    androidtv: {
      prices: {
        monthly: {
          id: "primefight.sub.m",
          price: "6.99EUR",
        },
        yearly: {
          id: "primefight.sub.y",
          price: "69.99EUR",
        },
      },
    },
    firetv: {
      prices: {
        monthly: {
          id: "app.alteox.primefight",
          price: "6.99EUR",
        },
        yearly: {
          id: "app.alteox.primefight.yearly",
          price: "69.99EUR",
        },
      },
    },
  },
};

export default configs;
