import { Colors, Lightning, Registry, Router, VideoPlayer } from "@lightningjs/sdk";
import { loader, unloader } from "../lib/video-player";
import { PlayerTop } from "../components/Player/PlayerTop";
import { PlayerBottom } from "../components/Player/PlayerBottom";
import { Loading } from "../components/Loading/Loading";
import theme from "../lib/theme";

interface VideoPlayerEvent {
    event: Event;
    videoElement: HTMLVideoElement;
}

interface PlaybackTemplateSpec extends Lightning.Component.TemplateSpec {
    Loading: typeof Loading;
    TopContainer: {
        Top: typeof PlayerTop;
    };
    BottomContainer: {
        Controls: typeof PlayerBottom;
    };
}

interface PlaybackTypeConfig extends Lightning.Component.TypeConfig {
    IsPage: true;
}

const CONTROLS_HIDE_DELAY = 2000;
const LOADING_DELAY = 400;

const MIN_REWIND = 10;
const MAX_REWIND = 300; // 5 min max

export class Trailer
    extends Lightning.Component<PlaybackTemplateSpec, PlaybackTypeConfig>
    implements Lightning.Component.ImplementTemplateSpec<PlaybackTemplateSpec>
{
    _duration = 0;
    _currentTime = 0;
    _playing = false;
    _controlsTimeout: undefined | number;
    _loadingTimeout: undefined | number;
    _loadingTimeoutDelay = LOADING_DELAY;

    _loading = true;

    _playTimeout: undefined | number;
    _progressTimeout: undefined | number;

    _movieId: number | undefined;
    _shifting = false;
    _shiftTimeout: undefined | number;
    _controlsHideDelay = CONTROLS_HIDE_DELAY;
    _rewindSeconds = MIN_REWIND;

    _boundEventHandler: any;

    static _overlayPadding = 120;

    static override _template(): Lightning.Component.Template<PlaybackTemplateSpec> {
        return {
            collision: true,
            Loading: { type: Loading, alpha: 1 },
            TopContainer: {
                collision: true,
                h: this._overlayPadding * 2,
                w: theme.layout.screenW,
                rect: true,
                colorTop: Colors(theme.color.overlay).alpha(0.6).get(),
                colorBottom: Colors(theme.color.overlay).alpha(0.001).get(),
                Top: {
                    type: PlayerTop,
                    x: 72,
                    y: this._overlayPadding / 2,
                    signals: {
                        playerTopHovered: "_handlePlayerTopHovered"
                    }
                }
            },
            BottomContainer: {
                w: theme.layout.screenW,
                h: this._overlayPadding * 2,
                y: theme.layout.screenH - this._overlayPadding * 2,
                rect: true,
                colorTop: Colors(theme.color.overlay).alpha(0.01).get(),
                colorBottom: Colors(theme.color.overlay).alpha(0.6).get(),
                collision: true,
                Controls: {
                    y: this._overlayPadding,
                    collision: true,
                    type: PlayerBottom
                    // x: PLAYER_OFFSETS.x,
                    // y: this._overlayPadding
                }
            }
        };
    }

    readonly TopContainer = this.getByRef("TopContainer")!;
    readonly Top = this.TopContainer.getByRef("Top")!;
    // readonly Controls = this.getByRef("Controls")!;
    readonly BottomContainer = this.getByRef("BottomContainer")!;
    readonly Controls = this.BottomContainer.getByRef("Controls")!;
    readonly Loading = this.getByRef("Loading")!;

    override _firstActive() {
        VideoPlayer.consumer(this);
        VideoPlayer.position(0, 0);
        VideoPlayer.loader(loader);
        // VideoPlayer.unloader(unloader);
    }

    override _setup() {
        this._boundEventHandler = this._resumePlaying.bind(this);
    }

    override _active() {
        console.log("playback is active");
        this._loadingTimeoutDelay = 0;

        this._setLoadingState();

        Registry.addEventListener(window, "online", this._boundEventHandler);
    }

    override async _inactive() {
        console.log("playback is inactive");

        this.application.emit("showBackground");

        this._destroyPlayer();

        Registry.removeEventListener(window, "online", this._boundEventHandler);
    }

    override _handleBack() {
        Registry.clearTimeouts();

        VideoPlayer.pause();

        if (!Router.getHistory().length) {
            // deeplink
            Router.navigate("home");
        } else {
            Router.back();
        }
    }

    override _handleEnter() {
        this.Controls.setPlayPauseState();

        this.$triggerVideoPlayPause();
    }

    override _handlePlayPause() {
        this.Controls.setPlayPauseState();

        this.$triggerVideoPlayPause();
    }

    override _handleForward() {
        this.Controls.setForwardState();

        this.$triggerVideoForward();
    }

    override _handleRewind() {
        this.Controls.setRewindState();

        this.$triggerVideoRewind();
    }

    override _handlePlay() {
        this.Controls.setPlayPauseState();

        this.$triggerVideoPlay();
    }

    override _handlePause() {
        this.Controls.setPlayPauseState();

        this.$triggerVideoPause();
    }

    override _handleStop() {
        this.Controls.setPlayPauseState();

        this.$triggerVideoPause();
    }

    override _captureKey() {
        this._setPlaybackStateTimeout();

        return false;
    }

    _handlePlayerTopHovered() {
        this._setState("TopState");
    }

    _setPlaybackStateTimeout() {
        this._clearControlsTimeout();

        this._controlsTimeout = Registry.setTimeout(() => {
            this._controlsTimeout = undefined;

            if (!this._playing) return;

            this._clearLoadingTimeout();
            this._setState("PlaybackState");

            this._controlsHideDelay = CONTROLS_HIDE_DELAY;
        }, this._controlsHideDelay);
    }

    _clearControlsTimeout() {
        if (this._controlsTimeout) {
            Registry.clearTimeout(this._controlsTimeout);
        }
    }

    _clearLoadingTimeout() {
        if (this._loadingTimeout) {
            Registry.clearTimeout(this._loadingTimeout);
            this._loadingTimeout = undefined;
        }
    }

    _destroyPlayer() {
        this._duration = 0;

        VideoPlayer.close();
        VideoPlayer.clear();
    }

    _initPlayer(data: string) {
        this._destroyPlayer();

        VideoPlayer.loader(loader);
        // VideoPlayer.unloader(unloader);
        VideoPlayer.open(data);
    }

    _handleCurrentTimeChange(time: number, togglePlay = false, rewind = true) {
        if (rewind) {
            this._shifting = true;
            if (this._shiftTimeout) {
                Registry.clearTimeout(this._shiftTimeout);
                this._shiftTimeout = undefined;
            }
            this._shiftTimeout = Registry.setTimeout(() => {
                this._shiftTimeout = undefined;
                this._shifting = false;
                this._rewindSeconds = MIN_REWIND;
            }, 450);
        }

        // VideoPlayer.pause();
        this._currentTime = Math.min(this._duration, Math.max(0, time));

        this.Controls.patch({
            currentTime: this._currentTime
        });

        if (togglePlay) {
            if (this._playTimeout) clearTimeout(this._playTimeout);
            this._playTimeout = undefined;
            this._playTimeout = Registry.setTimeout(() => {
                this._playTimeout = undefined;

                VideoPlayer.play();
            }, 450);
        }

        if (!this._progressTimeout) {
            this._progressTimeout = Registry.setTimeout(async () => {
                this._progressTimeout = undefined;
            }, 10000);
        }
    }

    _setLoadingState() {
        this._clearLoadingTimeout();

        this._loadingTimeout = Registry.setTimeout(() => {
            this._setState("LoadingState");

            this._loadingTimeoutDelay = LOADING_DELAY;
        }, this._loadingTimeoutDelay);
    }

    _setControlsState() {
        this._clearLoadingTimeout();
        this._setState("ControlsState");
    }

    _hideControls() {
        this.Loading.patch({
            smooth: {
                alpha: 0
            }
        });

        this.TopContainer.patch({
            smooth: {
                y: -400
            }
        });

        this.Controls.patch({
            smooth: {
                alpha: 0
            }
        });

        this.BottomContainer.patch({
            smooth: {
                y: 1080
            }
        });
    }

    _resumePlaying() {
        if (!this._playing) return;

        VideoPlayer.play();
    }

    $triggerVideoPlayPause() {
        VideoPlayer.playPause();

        this.Controls.triggeredPlayPause();
    }

    $triggerVideoPlay() {
        VideoPlayer.playPause();

        this.Controls.triggeredPlay();
    }

    $triggerVideoPause() {
        VideoPlayer.playPause();

        this.Controls.triggeredPause();
    }

    $triggerVideoRewind() {
        this._rewindSeconds = Math.min(this._rewindSeconds + 5, MAX_REWIND);

        VideoPlayer.pause();
        VideoPlayer.skip(this._rewindSeconds * -1);

        this._handleCurrentTimeChange(this._currentTime - this._rewindSeconds, this._playing, true);
    }

    $triggerVideoForward() {
        this._rewindSeconds = Math.min(this._rewindSeconds + 5, MAX_REWIND);

        VideoPlayer.pause();
        VideoPlayer.skip(this._rewindSeconds);

        this._handleCurrentTimeChange(this._currentTime + this._rewindSeconds, this._playing, true);
    }

    $videoPlayerTimeUpdate({ videoElement }: VideoPlayerEvent) {
        if (!this._shifting) {
            this._handleCurrentTimeChange(videoElement.currentTime);
        }
    }

    $videoPlayerDurationChange({ videoElement }: VideoPlayerEvent) {
        if (videoElement.duration && !this._duration) {
            this._duration = videoElement.duration;

            this.Controls.patch({
                currentTime: 0,
                duration: this._duration
            });
        }
    }

    $videoPlayerPause() {
        if (this._loading) return;

        this._setControlsState();

        this._playing = false;
    }

    $videoPlayerStop() {
        this._setControlsState();

        this._playing = false;
    }

    $videoPlayerPlaying() {
        if (this._loading) return;

        this._setPlaybackStateTimeout();

        this._playing = true;

        this.Controls.setPlayState(true);
    }

    $videoPlayerCanPlay() {
        this._setControlsState();

        this._loading = false;
    }

    $videPlayerLoading() {
        this._setLoadingState();

        this._playing = false;
    }

    $videoPlayerSeeking() {
        this._setLoadingState();
    }

    $videoPlayerSeeked() {
        VideoPlayer.play();
    }

    $videoPlayerEnded() {
        this._handleBack();
    }

    $exitVideo() {
        this._handleBack();
    }

    static override _states() {
        return [
            class TopState extends this {
                override _getFocused() {
                    return this.Top;
                }

                override _handleDown() {
                    this._setControlsState();
                }

                override _handleBack() {
                    if (this._playing) {
                        this._controlsHideDelay = 0;
                        this._setPlaybackStateTimeout();
                    } else {
                        return false;
                    }
                }
            },
            class ControlsState extends this {
                override $enter() {
                    this._setPlaybackStateTimeout();
                }

                override _getFocused() {
                    return this.Controls;
                }

                override _handleUp() {
                    this._clearLoadingTimeout();
                    this._setState("TopState");
                }

                override _handleBack() {
                    if (this._playing) {
                        this._controlsHideDelay = 0;
                        this._setPlaybackStateTimeout();
                        return true;
                    } else {
                        return false;
                    }
                }
            },
            class BottomState extends this {
                override _getFocused() {
                    return this.Controls;
                }

                override _handleUp() {
                    this._setControlsState();
                }
            },

            class LoadingState extends this {
                override _getFocused() {
                    return this._loading ? undefined : this.Controls;
                }

                override $enter() {
                    this.Controls.patch({
                        smooth: {
                            alpha: 0
                        }
                    });

                    this.Loading.patch({
                        smooth: {
                            alpha: 1
                        }
                    });
                }

                override $exit() {
                    this._clearLoadingTimeout();

                    this.Controls.patch({
                        smooth: {
                            alpha: 1
                        }
                    });

                    this.Loading.patch({
                        smooth: {
                            alpha: 0
                        }
                    });

                    this.application.emit("hideBackground");
                }
            },
            class PlaybackState extends this {
                override _captureKey() {
                    // Back button timeout not to trigger controls to exit
                    Registry.setTimeout(() => {
                        this._setControlsState();
                    }, 10);

                    return false;
                }

                _handleClick() {
                    this._setControlsState();
                }

                override _getFocused() {
                    return this.Controls;
                }

                override $enter() {
                    this._hideControls();
                }

                override $exit() {
                    this._clearLoadingTimeout();

                    this.TopContainer.patch({
                        smooth: {
                            y: 0
                        }
                    });

                    this.Controls.patch({
                        smooth: {
                            alpha: 1
                        }
                    });

                    this.BottomContainer.patch({
                        smooth: {
                            y: theme.layout.screenH - 120 * 2
                        }
                    });
                }
            }
        ];
    }

    set data(data: string) {
        this._initPlayer(data);
    }

    set redirect(redirectToMovie: boolean) {
        if (redirectToMovie) Router.navigate(`movie/${this._movieId}`);
    }
}
