import configs from "../lib/configs";
import { Storage } from "@lightningjs/sdk";
import { STORAGE_KEYS } from "../lib/utils";
import { Metrics } from "@firebolt-js/sdk";

const baseApiUrl = process.env.APP_API_URL || `https://genapp-backend.alteox.app`;

export function api<T>(url: string, options?: any): Promise<T> {
    if (options?.params) {
        const searchParams = new URLSearchParams();
        Object.keys(options.params).forEach((key) => searchParams.append(key, options.params[key] as string));

        url += `?${searchParams.toString()}`;
    }
    // console.log(`api ${JSON.stringify(options)}`);

    let headers: any = {
        "Content-Type": "application/json",
        "x-api-key": "Hf2cIPMNHvH3lws8AMcuhwahP151wmqs",
        "x-app-id": configs.appId,
        "x-app-language": Storage.get(STORAGE_KEYS.selectedLanguage) || "en",
        "x-app-platform": Storage.get(STORAGE_KEYS.platform),
        "x-app-device-id": Storage.get(STORAGE_KEYS.uid)
    };
    const token = Storage.get(STORAGE_KEYS.token);
    if (!!token) {
        headers.authorization = Storage.get(STORAGE_KEYS.token);
    }
    headers = {
        ...headers,
        ...(options?.headers || {})
    };

    return fetch(`${baseApiUrl}/${url}`, {
        method: options?.method || "POST",
        body: options?.body ? JSON.stringify(options.body) : undefined,
        headers: headers,
        signal: options?.signal
    })
        .then((response) => {
            console.log(`FETCH RES: ${response.status}`);
            if (!response.ok) {
                Metrics.error(
                    Metrics.ErrorType.NETWORK,
                    response.status.toString(),
                    "Fetch request could not be completed",
                    true
                );
                throw new Error(response.statusText);
            }

            return response.json();
        })
        .then((data) => {
            if (data.errors) {
                if (!data.errors[0]?.extensions?.code) {
                    throw new Error("Fetch error");
                }
            }

            return data;
        })
        .catch((error: Error) => {
            console.log("FETCH ERR");
            console.log(error);
            Metrics.error(Metrics.ErrorType.NETWORK, "500", "Fetch request could not be completed", true);
            throw error;
        });
}
