import { Colors, Img, Lightning, Utils } from "@lightningjs/sdk";
import { MovieModel } from "../../lib/models";
import theme from "../../lib/theme";

interface MoviePosterTemplateSpec extends Lightning.Component.TemplateSpec {
    data: MovieModel;
    Background: {
        Poster: object;
        Placeholder: object;
        Radius: object;
        Border: object;
    };
}

export class MoviePoster
    extends Lightning.Component<MoviePosterTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<MoviePosterTemplateSpec>
{
    _src: string | undefined;
    _type!: string;
    _isActive = false;

    _boundEventHandler?: any;

    static override _template(): Lightning.Component.Template<MoviePosterTemplateSpec> {
        return {
            Background: {
                w: (w) => w,
                h: (h) => h,
                Placeholder: {
                    mount: 0.5,
                    w: theme.mediaPlaceholder.w,
                    h: theme.mediaPlaceholder.h,
                    x: (w) => w / 2,
                    y: (y) => y / 2,
                    texture: Lightning.Tools.getSvgTexture(
                        Utils.asset("images/placeholder.png"),
                        theme.mediaPlaceholder.w,
                        theme.mediaPlaceholder.h
                    )
                },
                Poster: {
                    w: (w) => w,
                    h: (h) => h,
                    mount: 0.5,
                    x: (w) => w / 2,
                    y: (y) => y / 2,
                    alpha: 0.01
                },
                Radius: {
                    x: -8,
                    y: -8,
                    alpha: 1
                },
                Border: {
                    mount: 0.5,
                    x: (w) => w / 2 + theme.mediaItem.highlightW / 2 - theme.mediaItem.highlightW / 2,
                    y: (y) => y / 2 + theme.mediaItem.highlightW / 2 - theme.mediaItem.highlightW / 2,
                    alpha: 0
                }
            }
        };
    }

    readonly Background = this.getByRef("Background")!;
    readonly Radius = this.Background.getByRef("Radius")!;
    readonly Border = this.Background.getByRef("Border")!;
    readonly Poster = this.Background.getByRef("Poster")!;
    readonly Placeholder = this.Background.getByRef("Placeholder")!;

    set data(value: MovieModel) {
        this._src = value?.coverUrl;
        this._type = value?.__typename;

        this._update();
    }

    override _setup() {
        this._boundEventHandler = this._showPoster.bind(this);
    }

    override _init() {
        // @ts-expect-error: types are missing
        if (theme.mediaColors && theme.mediaColors[this.parent?.parent?.componentIndex % 4]) {
            this.Background.patch({
                // color: Colors(theme.mediaColors[this.parent!.parent!.componentIndex % 4]).get(),
                texture: Lightning.Tools.getRoundRect(
                    theme.mediaItem.posterW,
                    theme.mediaItem.posterH,
                    theme.mediaItem.radius,
                    0,
                    undefined,
                    true,
                    // @ts-expect-error: types are missing
                    Colors(theme.mediaColors[this.parent!.parent!.componentIndex % 4]).get()
                )
            });
        }
    }

    override _active() {
        this.Poster.on("txLoaded", this._boundEventHandler);

        this._isActive = true;

        this._update();
    }

    override _inactive() {
        this.Poster.off("txLoaded", this._boundEventHandler);

        this._isActive = false;

        this._update();

        // this.stage.gc(true);
    }

    override _focus() {
        this._createBackground();
    }

    override _unfocus() {
        this._createBackground();
    }

    _createBackground() {
        this.Border.setSmooth("alpha", this.hasFocus() ? 1 : 0);
    }

    _showPoster() {
        this.Placeholder.setSmooth("alpha", 0);
        this.Poster.setSmooth("alpha", 1);
    }

    _update() {
        if (this._isActive) {
            const width = this._type === "Episode" ? theme.episodeItem.posterW : theme.mediaItem.posterW;
            const height = this._type === "Episode" ? theme.episodeItem.posterH : theme.mediaItem.posterH;

            this.Border.patch({
                texture: Lightning.Tools.getRoundRect(
                    width,
                    height,
                    theme.mediaItem.radius,
                    theme.mediaItem.highlightW,
                    Colors(theme.color.highlight).get(),
                    false
                )
            });

            this.Radius.patch({
                texture: Lightning.Tools.getRoundRect(
                    width + 8,
                    height + 8,
                    theme.mediaItem.radius,
                    8,
                    Colors(theme.color.container).get(),
                    false
                )
            });

            if (this._src) {
                const imgWidth = this._type === "Episode" ? theme.episodeItem.posterImgW : theme.mediaItem.posterImgW;
                const imgHeight = this._type === "Episode" ? theme.episodeItem.posterImgH : theme.mediaItem.posterImgH;

                this.Poster.patch({
                    w: imgWidth,
                    h: imgHeight,
                    texture: Img(`${this._src}?width=${imgWidth}&quality=80`).cover(imgWidth, imgHeight)
                });
            }
        }
    }
}
