import { Colors, Lightning, Utils } from "@lightningjs/sdk";
import { MovieModel } from "../../lib/models";
import { MoviePoster } from "../MoviePoster/MoviePoster";
import theme from "../../lib/theme";
import { TranslatableText } from "../TranslatableText/TranslatableText";

interface MoviePosterWithLabelTemplateSpec extends Lightning.Component.TemplateSpec {
    data: MovieModel;
    isContinueWatching: boolean | undefined;
    posterHeight: number;
    Background: typeof MoviePoster;
    Label: object;
    Badge: {
        Icon: object;
        Text: typeof TranslatableText;
    };
}

export class MoviePosterWithLabel
    extends Lightning.Component<MoviePosterWithLabelTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<MoviePosterWithLabelTemplateSpec>
{
    _data: MovieModel | undefined;
    _isContinueWatching: boolean = false;

    static override _template(): Lightning.Component.Template<MoviePosterWithLabelTemplateSpec> {
        return {
            // flex: { direction: "column" },
            collision: true,
            zIndex: 10,
            Background: {
                w: (w) => w,
                h: 356,
                type: MoviePoster,
                scale: 1,
                pivotX: 0.5,
                pivotY: 0.5
            },
            Label: {
                w: (w) => w,
                // flexItem: { marginTop: 36 },
                x: 2,
                text: {
                    text: "",
                    fontSize: 28,
                    lineHeight: 30,
                    fontFace: "Secondary-Regular",
                    textAlign: "left",
                    maxLines: 3,
                    textOverflow: "ellipsis",
                    textColor: Colors(theme.color.text).get()
                }
            },
            Badge: {
                alpha: 0,
                // mount: -1,
                x: (w) => w - 78,
                y: (h) => h - 38,
                w: 70,
                h: 30,
                Icon: {},
                Text: {
                    // w: (w) => w,
                    x: (w) => w / 2,
                    y: (h) => h / 2 + 1,
                    mountY: 0.5,
                    type: TranslatableText,
                    text: {
                        fontSize: 16,
                        textAlign: "center"
                    }
                }
            }
        };
    }

    readonly Background = this.getByRef("Background")!;
    readonly Label = this.getByRef("Label")!;
    readonly Badge = this.getByRef("Badge")!;
    readonly BadgeText = this.Badge.getByRef("Text")!;

    override _getFocused(): Lightning.Component | null | undefined {
        return this.Background;
    }

    set data(value: any) {
        this._data = value.data || value;

        this.Label.patch({
            y: (this._data?.__typename === "Episode" ? theme.episodeItem.posterH : theme.mediaItem.posterH) + 22,
            text: {
                text: this._data?.title || ""
            }
        });

        this.Background.patch({
            data: this._data
        });

        if (this._data?.isLiveNow) {
            this.Badge.patch({
                alpha: 1,
                texture: Lightning.Tools.getRoundRect(70, 30, 3, 0, undefined, true, Colors("#E50914").get()),
                Icon: {
                    w: 24,
                    h: 24,
                    x: 4,
                    y: (h) => h / 2,
                    mountY: 0.5,
                    src: Utils.asset(`icons/live-now.png`)
                }
            });
            this.BadgeText.patch({
                x: 30,
                key: "general.liveNow"
            });
        } else if (this._data?.isFree) {
            this.Badge.patch({
                alpha: 1,
                texture: Lightning.Tools.getRoundRect(70, 30, 3, 0, undefined, true, Colors(theme.color.focus).get())
            });
            this.BadgeText.patch({
                x: 20,
                key: "general.free"
            });
        }
    }

    get data(): MovieModel {
        return this._data!;
    }

    set posterHeight(value: number) {
        this.Background.patch({
            h: value
        });
    }

    set isContinueWatching(value: boolean) {
        this._isContinueWatching = value;
    }

    override _focus() {
        this.Background.patch({ smooth: { scale: 1.08 } });

        if (this._data) {
            if (!this._isContinueWatching && this._data.__typename === "Episode") {
                // @ts-ignore
                window.selectedMovie = {
                    ...window.selectedMovie,
                    title: this._data.title,
                    duration: this._data.duration,
                    description:
                        this._data.description ||
                        window.selectedMovie?.series?.description ||
                        window.selectedMovie?.description ||
                        "",
                    series: window.selectedMovie?.series || window.selectedMovie
                };
            } else {
                window.selectedMovie = this._data;
            }

            this.application.emit("updateSelectedMovie", window.selectedMovie!);
        }
    }

    override _unfocus() {
        this.Background.patch({ smooth: { scale: 1 } });
    }

    override _handleEnter() {
        this._data && this.fireAncestors("$movieSelected", this._data);
    }

    _handleClick() {
        if (this.hasFocus()) {
            this._handleEnter();
        } else {
            this._data && this.fireAncestors("$movieClicked", this._data);
        }
    }
}
